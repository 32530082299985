<template>
  <div class="reserve">
    <div class="right">
      <div>
        <breadCrumb :siderContent="siderName" />
      </div>
      <div class="top">
        <a-input class="ipt" placeholder=" 请输入订单号" v-model="form.order_no" />
        <div class="name">退款日期:</div>
        <a-range-picker 
        class="myRange" 
        :placeholder="['开始时间', '结束时间']"
         @change="handleChange"
         v-model="time"
        >
          <a-icon slot="suffixIcon" type="calendar" />
        </a-range-picker>
        <div class="name">操作人:</div>
        <a-select class="mySelect" placeholder="请选择操作人" @change="getKey"  v-model="form.refund_clerk_id">
           <a-select-option value="">全部</a-select-option>
          <a-select-option   
            v-for="(item,index) in userArr"
            :key="index" 
            :value="item.clerk_id"
            >{{item.real_name}}
          </a-select-option>  
        </a-select>
        <div class="name">退款方式:</div>
        <a-select class="mySelect" placeholder="请选择退款方式" @change="getKey"  v-model="form.refund_type">
          <a-select-option   
            v-for="(item) in payTypeList"
            :key="item.key+'o'" 
            :value="item.type_name"
            >{{item.type_desc}}
          </a-select-option>  
        </a-select>
        <a-button class="smallOkBtn" @click="search">查询</a-button>
      </div>
      <div>
        <a-table
          :columns="columns"
          :data-source="detail"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          bordered
          :pagination="{
            current:searchForm.page,
            total:total,
            pageSize:7,
          }"
          @change="handleTableChange"
          :locale="{emptyText: '暂无数据'}"
        >
          <span slot="refundPayLog" slot-scope="text">
            <div v-for="(item,index) in text" :key="index">
              <div>{{item.type_desc}}：￥{{item.refund_price}}</div>
            </div>
          </span>
          <span slot="refund_order_id" slot-scope="text">
            <div style="color:#2a82e4;cursor: pointer;" @click="datailButton(text)">详情</div>
          </span>
           <span slot="refund_price" slot-scope="text">
            <div  class="refund_price">￥{{text}}</div>
          </span>
          <span slot="payed_price" slot-scope="text">
            <div>￥{{text}}</div>
          </span>
        </a-table>
      </div>
    </div>
    <returnDetail :isShow="showDetail" :refund_order_id="refund_order_id"  @close="showDetail=false"/>
  </div>
</template>
<script>
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import returnDetail from "@/views/bill/modules/returnDetail.vue";
import {orderRefundList} from '@/request/bill.js';
import {requestUserClerkList} from '@/request/founding.js';
import { payType } from '@/request/reportForm'
const columns = [
  {
    title: "退货单号",
    dataIndex: "refund_order_no",
    scopedSlots: { customRender: "refund_order_no" },
    width:'14%'
  },
  {
    title: "原订单号",
    dataIndex: "order_no",
    scopedSlots: { customRender: "order_no" },
    width:'14%'
  },
  {
    title: "退款金额",
    dataIndex: "refund_price",
    scopedSlots: { customRender: "refund_price" }, 
    width:'10%'
  },
  {
    title: "实收金额",
    dataIndex: "payed_price",
    width:'10%',
     scopedSlots: { customRender: "payed_price" }, 

  },
  {
    title: "退款方式",
    dataIndex: "refundPayLog",
    width:'20%',
    scopedSlots: { customRender: "refundPayLog" },
  },
  {
    title: "退款日期",
    dataIndex: "create_time",
  },
  {
    title: "操作人",
    dataIndex: "real_name",
    width:'8%'
  },
  {
    title: "操作",
    dataIndex: "refund_order_id",
    width:'10%',
    scopedSlots: { customRender: "refund_order_id" },
  },
];
export default {
  data() {
    return {
      members: [],
      siderName: "退货单", //sider切换内容
      form:{
        order_no:"",
        start_time:"",
        end_time:"",
        refund_type:undefined,
        page:1,
        listRows:7,
        refund_clerk_id:undefined,
      },
      detail:[],
      columns,
      searchForm:{
         page:1,
      },
      current:1,//当前页
      total:0,//总数据条数
      time:[],//时间数组
      userArr:[],//店员数组
      showDetail:false,
      refund_order_id:"",//详情页id
      payTypeList:[],
    };
  },
  components: {
    breadCrumb,
    returnDetail
  },
  mounted(){
    this.refundRequest()
    this.userRequest()
    payType().then(res => {
          if(res.data.status === 200){
              this.payTypeList = res.data.data
          }
      })
  },
  methods:{
    getKey(value){//获取状态
      this.status=value;
    },
    refundRequest(){
      this.searchForm=this.form;
      orderRefundList(this.searchForm)
        .then((res) => {
          if(res.data.status===200){
              this.detail=res.data.data.list.data;
              this.total=res.data.data.list.total && res.data.data.list.total;
            //  console.log(JSON.stringify(this.detail))
            }               
          })
        .catch(result => {
            return false;
      })
    },
    handleChange(value,dateString) { //获取时间
     if(dateString){
      //  this.time=dateString;
       this.form.start_time=dateString[0] && dateString[0];
       this.form.end_time=dateString[1] && dateString[1];
     }
    },
    search(){
      this.searchForm.page=1;
      this.refundRequest()
    },
    handleTableChange(e){ //点击分页
      this.form.page = e.current;
      this.refundRequest()
    },
    datailButton(id){
      this.showDetail=true;
      this.refund_order_id=id;

    },
    userRequest(){ //店员列表请求
      requestUserClerkList()
        .then((res) => {
          if(res.data.status===200){
              this.userArr=res.data.data.list&&res.data.data.list;
              // console.log(JSON.stringify(this.userArr))
            }               
          })
        .catch(result => {
            return false;
      })
    },
  }
};
</script>

<style lang="less" scoped>
.reserve {
  width: 100%;
  height: 95.4vh;
  overflow-y: auto;
  overflow-x:hidden;
  background: #f8f8ff;
  .right {
    width: 83vw;
  }
}
/deep/.ant-table{
  margin: 0 2vh 0 2vh;
}
.top {
  display: flex;
  margin: 1.5vh 1.5vh 1.5vh 1.5vh;
  flex-wrap: wrap;
  .ipt {
    width: 15vw;
    margin-right: 3vh;
    height:3.9vh;
    margin-bottom: 1vh;
  }
  .myRange {
    width: 21vw;
    margin-right: 3vh;
  }
  .mySelect {
    width: 14vw;
    margin-right: 3vh;
  }
  .name{
    height:3.9vh;
    line-height: 3.9vh;
    font-size: 1.8vh;
    margin-right: 5px;
  }
  .myButton {
    height:3.9vh;
    width: 8vw;
    margin-right: 2.5vh;
    background-color: #ff6700;
    border: none;
    color: white;
    font-size: 1.8vh;
  }
  .button {
    width: 10vh;
    height:3.9vh;
    font-size: 1.8vh;
  }
  .myTable{
    margin: 2.5vh;
  }
}
/deep/.ant-pagination{
  margin-right:4vw;
}
/deep/td{
  padding:1.5vh !important;
  background: white !important;
  font-size: 1.8vh;
  // padding:10px !important;
}  
/deep/th{
  background: rgba(248, 248, 255, 1) !important;
  font-size: 1.8vh;
  padding:10px !important;
}
/deep/.ant-calendar-picker-input{
   font-size: 1.8vh;
   height: 3.9vh;
}
/deep/.ant-select-selection__rendered{
 font-size: 1.8vh;
  height: 3.9vh;
  line-height: 3.9vh;
}
/deep/.ant-select-selection--single{
 height: 3.9vh;
  line-height: 3.9vh;
}
/deep/.smallOkBtn{
  width:8vw !important;
}  
/deep/.card-title{
  margin-top:2vh !important;
}
/deep/.refund_price{
  color: #ff6700;
}
</style>