<template>
  <div>
    <a-modal
      :visible="isShow"
      title="退货详情"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <span class="cancel">
          <a-button key="back" class="clearBtn" @click="handleCancel"> 取消 </a-button>
        </span>
        <span class="primary">
          <a-button class="OkBtn submit" type="primary" @click="handleOk">
            确定
          </a-button>
        </span>
      </template>
        <div class="first">
          <b class="bzero">退货单号: {{detail?detail.refund_order_no:""}}</b>
          <b class="bone">退货时间: {{detail?detail.create_time:""}}</b>
          <b class="btwo">退货人: {{detail?detail.real_name:""}}</b>
        </div>
        <div class="third">
          <b class="yuan">原订单号: {{detail?detail.order_no:""}}</b>
          <b class="bthree">退款金额:{{detail?detail.refund_price:""}}</b>
        </div>
        <div class="second">
          <p class="pone">
            退款方式: 
            <span v-for="(item,index) in detail.refundPayLog" :key="index" class="type">
              <span class="jia">+</span>
              <span>{{item.type_desc}}</span>
              <span>({{item.refund_price}})</span> 
            </span>
          </p>
          <p class="pzero"><span v-if="detail.name"> 接收人:{{detail?detail.name:""}}</span>  <span v-if="detail.mobile">电话:{{detail?detail.mobile:""}}</span></p>
        </div>
      <div>
        <a-table
          :columns="columns"
          :data-source="members"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          bordered
          style="width: 100%; margin-top: 1vh"
        >
          <span slot="is_stock_total" slot-scope="text">
              <div v-if="text==1">是</div>
              <div v-if="text==2">否</div>
          </span>
           <span slot="refund_price" slot-scope="text">
              <div>￥{{text}}</div>
          </span>
          <span slot="total_num" slot-scope="text">
              <div>×{{text}}</div>
          </span>
          <span slot="refund_total_num" slot-scope="text">
              <div>×{{text}}</div>
          </span>
        </a-table>
        <div class="total">
            <div class="count">
              <div class="left">总计退款:￥{{detail?detail.refund_price:""}}</div>
              <div class="right">退货数量:{{detail?detail.refundTotalNum:""}}</div>
            </div>
        </div>
      
      </div>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: "商品",
    dataIndex: "goods_name",
    width:"20%"
  },
  {
    title: "原单数量",
    dataIndex: "total_num",
    scopedSlots: { customRender: "total_num" }, 
    width:"10%"
  },
  {
    title: "规格",
    dataIndex: "spec_content",
    width:"10%"
  },
  {
    title: "原价",
    dataIndex: "refund_price",
    scopedSlots: { customRender: "refund_price" }, 
    width:"10%"
  },
  {
    title: "口味",
    dataIndex: "taste",
    width:"10%"
  },
  {
    title: "忌口",
    dataIndex: "avoid",
    width:"10%"
  },
  {
    title: "做法",
    dataIndex: "practice",
    width:"10%"
  },
   {
    title: "退货数量",
    dataIndex: "refund_total_num",
    scopedSlots: { customRender: "refund_total_num" },
    width:"10%"
  },
  {
    title: "是否退库存",
    dataIndex: "is_stock_total",
    scopedSlots: { customRender: "is_stock_total" }, 
    width:"10%"
  },
];
import {orderRefundDetail} from '@/request/bill.js';
export default {
  props:['isShow',"refund_order_id"],
  data() {
    return {
      members:[],
      columns,
      detail:{}
    };
  },
  watch:{
    isShow:{
      handler(){
        if(this.isShow){
          orderRefundDetail({refund_order_id:this.refund_order_id}).then((res) => {
            if(res.data.status===200){
              this.detail=res.data.data;
              this.members=res.data.data.refundGoodsList
              }               
            })
          .catch(result => {
              return false;
          })
        }
      },
      deep:true
    }
  },
  methods: {
    handleCancel() {
      this.$emit('close')
    },
    handleOk(e) {
      this.$emit('close')
    },
  },
};
</script>
 <style lang="less" scoped>
/deep/.ant-modal {
  width: 79vw !important;
  top:50px;
}
.first{
  display: flex;
}
.second {
  display: flex;
  margin-top: 1vh;
  color: #a6a6a6;
}
.bzero {
  font-size: 1.8vh;
  width:28vw;
  display: inline-block;
}
.yuan{
  display: inline-block;
  font-size: 1.8vh;
  width:28vw;
  color:#2a82e4;
}
.bone {
  font-size: 1.8vh;
  width:28vw;
}
.btwo {
  font-size: 1.8vh;
  width:19vw;
}
.bthree {
  display:inline-block;
  font-size: 1.8vh;
  width:19vw;
}
.pzero {
  font-size: 1.4vh;
}
.pone {
  font-size: 1.4vh;
  width:28vw;
}
.third {
  font-size: 1.8vh;
  color: #ff3b30;
  margin-top: 1.8vh;
}
/deep/.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 0;
  font-size: 1.8vh;
  text-align: center;
}
/deep/.ant-table-tbody > tr > td {
  padding: 8px 8px;
  font-size: 1.6vh;
}
.total{
  display: flex;
  align-items: center;
  justify-content: center;
  .count{
    display: flex;
    color: #ff6600;
    font-size: 2.0vh;
    margin-top: 1vh;
    .left{
      margin-right: 2vw;
    }
  }
}
/deep/.ant-modal-footer{
  text-align: center;
}
.submit {
  margin: 0 0 0 3vh;
}
.type:first-child{
  .jia{
    display: none;
  }
}
</style>