import request from "@/request/request";

//店员列表
export function requestUserClerkList() {
    return request({
        url: "/clerk/user/clerkList",
        method: "post",
    });
}
//支付方式列表
export function payType(params) {     
    return request({
      url: "/clerk/payType/list",
      method: "get",
      params
    });
}

//订单统计
export function orderStatistics(data) {
    return request({
        url: "/clerk/order/orderStatistics",
        method: "post",
        data
    });
}

//导出Excel
export function exportOrderStatistics(params) {
    return request({
        url: "/clerk/order/exportOrderStatistics",
        method: "get",
        params
    });
}
//数据统计
export function dataStatistics(data) {
    return request({
        url: "/clerk/order/dataStatistics",
        method: "post",
        data
    });
}