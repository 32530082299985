//开台详情页接口请求方法
import request from "@/request/request";


export function billList(data) {    //账单列表
  return request({
    url: "/clerk/order/billList",
    method: "post",
    data: data,
  });
}
export function antiOrder(data) {     //反结账
  return request({
    url: "/clerk/order/antiOrder",
    method: "post",
    data: data,
  });
}
export function hangOrder(data) {    //销账
  return request({
    url: "/clerk/order/hangOrder",
    method: "post",
    data: data,
  });
}

export function refundGoodsList(data) {    //获取支持退款商品列表
  return request({
    url: "/clerk/order/refundGoodsList",
    method: "post",
    data: data,
  });
}

export function refundOrder(data) {    //退款
  return request({
    url: "/clerk/order/refundOrder",
    method: "post",
    data: data,
  });
}

export function refundOrderDetail(data) {    //获取订单退款详情
  return request({
    url: "/clerk/order/refundOrderDetail",
    method: "post",
    data: data,
  });
}
export function transactionDetails(data) {    //获取交易明细
  return request({
    url: "/clerk/order/transactionDetails",
    method: "post",
    data: data,
  });
}
export function orderRefundList(data) {    //获取退货单列表
  return request({
    url: "/clerk/order/orderRefundList",
    method: "post",
    data: data,
  });
}
export function orderRefundDetail(data) {    //退货单详情
  return request({
    url: "/clerk/order/orderRefundDetail",
    method: "post",
    data: data,
  });
}





